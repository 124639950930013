import english from "@/locales/en/translations.json";

interface ErrorRange {
  range: number[];
  message: string;
}

export const ErrorCodeMsg: ErrorRange[] = [
  /*=====| System Errors  |=====*/
  {
    range: [50000, 50001, 50003, 50005, 50006, 50007, 50008],
    message: "System Encountered An Error. Please Try Again",
  },
  { range: [50060, 50061], message: "Connection Failed" },
  { range: [40102], message: "Connection Has Ended." },
  { range: [40174], message: "Invalid Token" },
  { range: [10000], message: "System Error" },
  { range: [10021], message: "No Game Activity Has Been Detected" },
  {
    range: [10031],
    message:
      "We've Detected A Recent Login To Your Account. Please Log In Again",
  },
  {
    range: [10032],
    message: "User Logout Has Been Detected",
  },
  {
    range: [10033],
    message: "Connection has ended",
    // Msg from BE: Server Connection Lost
  },
  { range: [10034], message: "Session Ended Due To Inactivity" },
  { range: [10035], message: "Connection Ends Due To Network Error" },

  // Login Errors
  { range: [40080, 40081, 40082], message: "Invalid Credentials" },

  /*=====| Betting Errors  |=====*/
  {
    range: [
      40042, 40043, 40045, 40046, 40047, 40049, 40051, 40052, 40053, 40054,
      40055, 40059, 50050, 40901,
    ],
    message: "Betting has failed",
  },
  {
    range: [40044],
    message: "Balance Not Synchronized",
  },
  {
    range: [40050],
    message: "Under Maintenance",
  },
  {
    range: [40048],
    message: "Please Bet Player or Banker First",
  },
  {
    range: [40056],
    message: "The table's Player betting limit has been exceeded",
  },
  {
    range: [40057],
    message: "The table's Banker betting limit has been exceeded",
  },
  {
    range: [40058],
    message: "Side bets are only allowed up to the 44th round.",
  },
];

/**
 *
 * @param code  If theres no code received "System Error" will be the fallback
 * @param message  I created a util function to turn this the string to an key for multi language. Yet to be implemented
 * @returns key for multilanguage. (e.g., "Key One" -> key_one)
 */
export const getAllErrorMessage = (code: number, message: string): string => {
  const noCodeMsg = "System Error"
    .trim()
    .replace(/_/g, "")
    .replace(/[^a-zA-Z0-9]/g, "_")
    .replace(/_+/g, "_")
    .replace(/^_+|_+$/g, "")
    .toLowerCase();

  if (code === undefined || code === null) {
    return noCodeMsg;
  }

  const error = ErrorCodeMsg.find(({ range }) => range.includes(code));

  const newMessage = message
    .trim()
    .replace(/_/g, "")
    .replace(/[^a-zA-Z0-9]/g, "_")
    .replace(/_+/g, "_")
    .replace(/^_+|_+$/g, "")
    .toLowerCase();

  const haskey = english.hasOwnProperty(newMessage);
  const filMsg = haskey ? newMessage : message;

  const newError = error
    ? error.message
        .trim()
        .replace(/_/g, "")
        .replace(/[^a-zA-Z0-9]/g, "_")
        .replace(/_+/g, "_")
        .replace(/^_+|_+$/g, "")
        .toLowerCase()
    : newMessage;
  return error ? newError : filMsg;
};
